const PROTOCOL = process.env.REACT_APP_LOGIN_PROTOCOL;
const HOST_NAME = process.env.REACT_APP_LOGIN_HOST;

export const addStudent = async (name, phoneNumber) => {
    try {
        const response = await fetch(`${PROTOCOL}://${HOST_NAME}/add_student`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name, phone_num: phoneNumber }),
            credentials: 'include'
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            console.error('Error adding student:', errorMessage);
            return null;
        }

        const student = await response.json();
        console.log('Student added:', student);

        return student;

    } catch (error) {
        console.error('There was a problem with the fetch operation:', error.message);
        return null;
    }
};

export const validateToken = async (token) => {
    try {
        console.log(`validate ${PROTOCOL}://${HOST_NAME}/api/validate_token`);
        const response = await fetch(`${PROTOCOL}://${HOST_NAME}/api/validate_token`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        });

        if (response.ok) {
            return true;
        }
        return false;
    } catch (error) {
        console.error('Error validating token:', error);
        return false;
    }
};

export const handleAddStudent = async (name, phoneNumber, callback) => {
    const student = await addStudent(name, phoneNumber);

    if (student && callback) {
        // If there's a provided callback (like refetching students), execute it:
        callback();
    }
};

export const deleteStudent = async (studentId) => {
    try {
        const response = await fetch(`${PROTOCOL}://${HOST_NAME}/delete_student/${studentId}`, {
            method: 'DELETE',
            credentials: 'include'
        });
        console.log(`${PROTOCOL}://${HOST_NAME}/delete_student/${studentId}`);
        if (response.status !== 200) {
            const data = await response.json();
            throw new Error(data);
        }

        return true;
    } catch (error) {
        console.error('Error deleting student:', error);
        return false;
    }
};

export const handleDeleteStudent = async (studentId, studentName, callback) => {
    const confirmDelete = window.confirm("Are you sure you want to delete "+studentName+" and all associated transactions?");
    
    if (!confirmDelete) {
        return; // Exit the function if the user doesn't confirm the delete action
    }

    const isSuccess = await deleteStudent(studentId);

    if (isSuccess) {
        // If there's a provided callback (like refetching students), execute it:
        callback();
    }
};
export const getTeachers = async () => {
    try {
        const response = await fetch(`${PROTOCOL}://${HOST_NAME}/get_teachers`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            console.error('Error fetching teachers:', errorMessage);
            return null;
        }

        const teachers = await response.json();
        return teachers;

    } catch (error) {
        console.error('There was a problem with the fetch operation:', error.message);
        return null;
    }
};
export const getTeacher = async (teacherId) => {
    try {
        const response = await fetch(`${PROTOCOL}://${HOST_NAME}/get_teacher/${teacherId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            console.error('Error fetching teacher:', errorMessage);
            return null;
        }

        const teacher = await response.json();
        return teacher;

    } catch (error) {
        console.error('There was a problem with the fetch operation:', error.message);
        return null;
    }
};
export const addTeacher = async (name) => {
    try {
        const response = await fetch(`${PROTOCOL}://${HOST_NAME}/add_teacher`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name }),
            credentials: 'include'
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            console.error('Error adding teacher:', errorMessage);
            return null;
        }

        const teacher = await response.json();
        console.log('Teacher added:', teacher);

        return teacher;

    } catch (error) {
        console.error('There was a problem with the fetch operation:', error.message);
        return null;
    }
};

export const handleAddTeacher = async (name, callback) => {
    const teacher = await addTeacher(name);

    if (teacher && callback) {
        // If there's a provided callback (like refetching teachers), execute it:
        callback();
    }
};

export const deleteTeacher = async (teacherId) => {
    try {
        const response = await fetch(`${PROTOCOL}://${HOST_NAME}/delete_teacher/${teacherId}`, {
            method: 'DELETE',
            credentials: 'include'
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            console.error('Error deleting teacher:', errorMessage);
            return false;
        }

        return true;
    } catch (error) {
        console.error('Error deleting teacher:', error.message);
        return false;
    }
};

export const handleDeleteTeacher = async (teacherId, teacherName, callback) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete ${teacherName}?`);

    if (!confirmDelete) {
        return; // Exit the function if the user doesn't confirm the delete action
    }

    const isSuccess = await deleteTeacher(teacherId);

    if (isSuccess && callback) {
        // If there's a provided callback (like refetching teachers), execute it:
        callback();
    }
};

export const deleteTransactionAPI = async (transactionId) => {
    try {
        const response = await fetch(`${PROTOCOL}://${HOST_NAME}/delete_transaction/${transactionId}`, {
            method: 'DELETE',
            credentials: 'include'
        });
        const data = await response.json();

        if (response.status !== 200) {
            throw new Error(data.message || 'Error deleting transaction.');
        }

        return true;
    } catch (error) {
        console.error('Failed to delete transaction:', error);
        return false;
    }
};

export const getTransactionsForStudentAPI = async (studentId) => {
    try {
        const response = await fetch(`${PROTOCOL}://${HOST_NAME}/get_transactions/${studentId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            console.error('Error fetching transactions for student:', errorMessage);
            return null;
        }

        const transactions = await response.json();

        return transactions;

    } catch (error) {
        console.error('There was a problem with the fetch operation:', error.message);
        return null;
    }
};

export const addTransactionAPI = async (studentId, amount, transactionDate, teacherId) => {
    try {
        const response = await fetch(`${PROTOCOL}://${HOST_NAME}/add_transaction`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                student_id: studentId,
                amount,
                transaction_date: transactionDate,
                teacher_id: teacherId
            }),
            credentials: 'include'
        });

        const data = await response.json();

        if (response.ok) {
            console.log(data.message);
            return data.transaction_id;
        } else {
            console.error(data.message || 'Error occurred while adding transaction.');
        }
    } catch (error) {
        console.error('Failed to add transaction:', error);
    }
};

// StudentAPI.js

export const archiveStudent = async (studentId, archive) => {
    try {
        const response = await fetch(`${PROTOCOL}://${HOST_NAME}/archive_student/${studentId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ archive }), // Sending archive boolean value
            credentials: 'include'
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            console.error('Error archiving/unarchiving student:', errorMessage);
            return null;
        }

        const result = await response.json();
        console.log('Student archived/unarchived:', result.message);

        return result;

    } catch (error) {
        console.error('There was a problem with the fetch operation:', error.message);
        return null;
    }
};

export const handleArchiveStudent = async (studentId, archive, callback) => {
    const confirmArchive = window.confirm(`Are you sure you want to ${archive ? 'archive' : 'unarchive'} this student?`);
    
    if (!confirmArchive) {
        return; // Exit the function if the user doesn't confirm the action
    }

    const result = await archiveStudent(studentId, archive);

    if (result && callback) {
        // If there's a provided callback (like refetching students), execute it:
        callback();
    }
};

export const handleQueryAmount = async (startDate, endDate, queryType) => {
    const backendURL = `${PROTOCOL}://${HOST_NAME}`;

    try {
        const response = await fetch(`${backendURL}/query_amount?start_date=${startDate}&end_date=${endDate}&type=${queryType}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            console.error('Error querying amount:', errorMessage);
            return null;
        }

        const result = await response.json();
        return result.total_amount;
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error.message);
        return null;
    }
};