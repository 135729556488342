import React, { useEffect, useState } from 'react';
import { getTeachers, handleDeleteTeacher as deleteTeacherAPI } from './StudentAPI';

const AddTeacherModal = ({ showModal, onClose, onAddTeacher }) => {
    const [teacherName, setTeacherName] = useState('');
    const [teachers, setTeachers] = useState([]);

    useEffect(() => {
        const fetchTeachers = async () => {
            const teachersList = await getTeachers();
            if (teachersList) {
                setTeachers(teachersList);
            }
        };

        fetchTeachers();
    }, []);

    const handleAddTeacher = async () => {
        await onAddTeacher(teacherName);
        const updatedTeachers = await getTeachers();
        setTeachers(updatedTeachers);
        setTeacherName('');
    };

    const handleDeleteTeacher = async (teacherId, teacherName) => {
        try {
            await deleteTeacherAPI(teacherId, teacherName);
            const updatedTeachers = await getTeachers();
            setTeachers(updatedTeachers);
        } catch (error) {
            console.error('Failed to delete teacher:', error);
        }
    };

    if (!showModal) {
        return null;
    }

    return (
        <div className="modal show" tabIndex="-1" style={{ display: 'block' }}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Add Teacher</h5>
                        <button type="button" className="btn-close" aria-label="Close" onClick={onClose}></button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label>Name:</label>
                            <input 
                                type="text" 
                                className="form-control"
                                value={teacherName}
                                onChange={e => setTeacherName(e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-3">
                            <label>Existing Teachers:</label>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {teachers.map(teacher => (
                                        <tr key={teacher.id}>
                                            <td>{teacher.id}</td>
                                            <td>{teacher.name}</td>
                                            <td>
                                                <button 
                                                    className="btn btn-danger btn-sm" 
                                                    onClick={() => handleDeleteTeacher(teacher.id, teacher.name)}
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button 
                            type="button" 
                            className="btn btn-primary"
                            onClick={handleAddTeacher}
                        >
                            Add
                        </button>
                        <button 
                            type="button" 
                            className="btn btn-secondary" 
                            onClick={onClose}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddTeacherModal;
