import React from 'react';
import Navbar from './Navbar';
import './style/contact.css';

const Contact = () => {
  // For simplicity, we're hardcoding the contact information.
  // In a real-world application, you'd probably fetch this information from an API or database.
  const contactInfo = {
    weChatID: 'TheFirstSpringStudio',
    instagram: 'dora.wu0310'
  };

  return (
    <div className="contact-page">
      <Navbar />
      <div className="contact-info">
        <div className="contact-item">
          <h4>Teacher Dora</h4>
        </div>
        <div className="contact-item">
          <strong>WeChat ID: </strong>
          <span>{contactInfo.weChatID}</span>
        </div>
        <a href={`https://www.instagram.com/${contactInfo.instagram}/`} className="instagram-link" target="_blank" rel="noopener noreferrer">
          <img src="./images/instagram.png" alt="Instagram Logo" className="instagram-logo" />
          Follow us on Instagram
        </a>
      </div>
    </div>
  );
};

export default Contact;
